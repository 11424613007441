.react-datepicker {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", sans-serif;
  overflow: hidden;
}
.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 90px;
}
.react-datepicker__navigation--previous,
.react-datepicker__navigation--next {
  height: 8px;
}
.react-datepicker__navigation--previous {
  border-right-color: #cbd5e0;
}
.react-datepicker__navigation--previous:hover {
  border-right-color: #a0aec0;
}
.react-datepicker__navigation--next {
  border-left-color: #cbd5e0;
}
.react-datepicker__navigation--next:hover {
  border-left-color: #a0aec0;
}
.react-datepicker-wrapper,
.react-datepicker__input-container {
  display: block;
}
.react-datepicker__input-container input {
  border: 1px solid #e2e8f0;
  width: 100%;
  outline: 0;
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  font-size: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  height: 2.5rem;
  border-radius: 0.375rem;
  border: 1px solid;
  border-color: inherit;
  background: inherit;
}
.react-datepicker__input-container input:focus,
.react-datepicker__input-container input.error:focus {
  border-color: #3182ce;
  box-shadow: 0 0 0 1px #3182ce;
}
.react-datepicker__input-container input.error {
  border-color: #e53e3e;
  box-shadow: 0 0 0 1px #e53e3e;
}
.react-datepicker-popper {
  z-index: 2;
}
.react-datepicker__header {
  border-radius: 0;
  background: #f7fafc;
}
.react-datepicker,
.react-datepicker__header,
.react-datepicker__time-container {
  border-color: #e2e8f0;
}
.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  font-size: inherit;
  font-weight: 600;
}
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item {
  margin: 0 1px 0 0;
  height: auto;
  padding: 7px 10px;
}
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item:hover {
  background: #edf2f7;
}
.react-datepicker__day:hover {
  background: #edf2f7;
}
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background: #3182ce;
  font-weight: normal;
}
.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected:hover {
  background: #2a69ac;
}
