.only-timepicker .react-datepicker,
.only-timepicker .react-datepicker-popper,
.only-timepicker .react-datepicker__time-container,
.only-timepicker .react-datepicker__time-container,
.only-timepicker .react-datepicker__time-box {
  width: 100% !important;
}

.only-timepicker .react-datepicker__input-container input:disabled {
  opacity: 0.4;
  cursor: not-allowed;
}
